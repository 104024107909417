body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*font-family: "Amita", "Orator W01", "Gill Sans", "Gill Sans MT", calibri, serif;*/
  /*font-weight: 400;*/
  /*font-style: normal;*/
  font-weight: 400;
  font-style: normal;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
